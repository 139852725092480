import React from "react";
import Page from "../components/Page";
import { graphql, Link } from "gatsby";
import BlogCard from "../components/Blog/BlogCard";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Index({ data }) {
  const { posts } = data.blog;

  return (
    <>
      <Page
        title={"Blomstertjänsten - Allt inom blommor"}
        description=" Få tips om hur du väljer rätt blommor, blomsterarrangemang och mycket mer för att skapa en blomstrande miljö."
      >
        <section class="bg-white">
          <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
            <div class="mr-auto place-self-center lg:col-span-7">
              <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl ">
              Den ultimata guiden om blommor
              </h1>
              <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl ">
             Få tips om hur du väljer rätt blommor, blomsterarrangemang och mycket mer!
              </p>

              <a
                href="/blogg/"
                class="inline-flex px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 "
              >
                Läs Bloggen
              </a>
            </div>
            <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
              <GatsbyImage
                image={data.image.childImageSharp.gatsbyImageData}
                backgroundColor="white"
                alt="studentmottagning"
              />
            </div>
          </div>
        </section>
        <section class="bg-white ">
          <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
            <GatsbyImage
              image={data.image2.childImageSharp.gatsbyImageData}
              alt="studenten"
              className="w-full rounded-lg"
            />
            <div class="mt-4 md:mt-0">
              <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
                Guider och tips för att skapa en blomstrande miljö
              </h2>
              <p class="mb-6 font-light text-gray-500 md:text-lg ">
              Vi hjälper dig välja rätt blommor, blomsterarrangemang och mycket mer för att ge liv åt alla evenemang eller miljöer.


              </p>
            </div>
          </div>
        </section>
        <section className="container px-4 py-8 mx-auto max-w-screen-xl">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
                Senaste artiklar
              </h2>
          <div className="blog-grid">
            {posts &&
              posts.map((post) => {
                return (
                  <BlogCard
                    key={post.id}
                    title={post.frontmatter.title}
                    description={post.excerpt}
                    link={post.fields.slug}
                    image={post.frontmatter?.coverImage}
                  />
                );
              })}
          </div>
        </section>

        <br />
        <br />
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query home {
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(/blog/)/" } }
      limit: 3
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        fields {
          slug
        }
        id
        excerpt(pruneLength: 120)
      }
    }
    image: file(relativePath: { eq: "imageTop.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    image2: file(relativePath: { eq: "home2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
